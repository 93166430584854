<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/users">Mitarbeiter</router-link>
        <p>Dashboard</p>
      </h1>
      <div class="meta">
        <a @click="get_data" class="button button-red">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrows-rotate" class="svg-inline--fa fa-arrows-rotate" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M464 16c-17.67 0-32 14.31-32 32v74.09C392.1 66.52 327.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.89 5.5 34.88-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c50.5 0 96.26 24.55 124.4 64H336c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32V48C496 30.31 481.7 16 464 16zM441.8 289.6c-16.92-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-50.5 0-96.25-24.55-124.4-64H176c17.67 0 32-14.31 32-32s-14.33-32-32-32h-128c-17.67 0-32 14.31-32 32v144c0 17.69 14.33 32 32 32s32-14.31 32-32v-74.09C119.9 445.5 184.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z"></path>
          </svg>
          <span>Termine/Warteschlange neu laden</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>
      <div class="row row-gutter-20">
        <div class="col-16">
          <div class="content-wrap">
            <h3>Aktueller Termin</h3>
            <div v-if="appointments && appointments.length == 0">
              <p>Sie haben aktuell keinen zugewiesenen Termin.</p>

              <div v-if="all_appointments && all_appointments.length == 0" class="alert alert-info">
                Aktuell sind keine Termine in der Warteschlange
              </div>
              <div v-else>
                <p @click="fetch_appointment" style="margin-bottom:0"><a href="#" class="button button-red">Jetzt effizientesten Termin aus Warteschlange aufrufen ›</a></p>

                <hr style="margin:30px 0 25px 0">

                <p>Folgende Termine sind in der Warteschlange:</p>

                <div v-for="appointment in all_appointments" v-bind:key="appointment.id">
                  <a @click="select_appointment(appointment)" style="color:#444;font-weight:600;border:1px solid #f1f1f1;padding: 20px;display:block">
                    {{ appointment.date_formatted }}, {{ appointment.time_formatted }} ({{ appointment.last_name }}, {{ appointment.first_name }})<br>
                    <span style="color:rgb(117, 117, 117);font-size:14px;font-weight:400;display:block;margin-top:6px">
                      {{ appointment.location.name }} / {{ appointment.category.name }} / {{ appointment.service.name }} /
                      <span v-if="appointment.appointment_type == 'planned'">T{{ appointment.appointment_number }}</span>
                      <span v-if="appointment.appointment_type == 'spontaneous'">A{{ appointment.appointment_number }}</span>
                    </span>
                    <span style="color:#9C2128;display:block;margin-top:6px">Diesen Termin auswählen ›</span>
                  </a>
                </div>

              </div>

            </div>
            <div v-else>
              <p>Folgende Termine sind bereits aktiv:</p>
              <div v-for="appointment in appointments" v-bind:key="appointment.id">
                <router-link :to="'/admin/appointments/'+appointment.id+'?user_id='+this.user.id" style="color:#444;font-weight:600">
                  {{ appointment.date_formatted }}, {{ appointment.time_formatted }} ({{ appointment.last_name }}, {{ appointment.first_name }}) –– Status:
                  <span v-if="appointment.status == 'created'" class="status-created">
                    <span class="status-icon"></span>
                    <span class="status-text">Reserviert</span>
                  </span>
                  <span v-if="appointment.status == 'checked_in'" class="status-checked_in">
                    <span class="status-icon"></span>
                    <span class="status-text">Eingecheckt</span>
                  </span>
                  <span v-if="appointment.status == 'called'" class="status-checked_in">
                    <span class="status-icon"></span>
                    <span class="status-text">Aufgerufen</span>
                  </span>
                  <span v-if="appointment.status == 'running'" class="status-running">
                    <span class="status-icon"></span>
                    <span class="status-text">Läuft</span>
                  </span>
                  <span v-if="appointment.status == 'canceled'" class="status-canceled">
                    <span class="status-icon"></span>
                    <span class="status-text">Storniert</span>
                  </span>
                  <span v-if="appointment.status == 'completed'" class="status-completed">
                    <span class="status-icon"></span>
                    <span class="status-text">Fertig</span>
                  </span>
                  <span v-if="appointment.status == 'paused'" class="status-paused">
                    <span class="status-icon"></span>
                    <span class="status-text">Pausiert</span>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="content-wrap">
            <h3>Schalter</h3>
            <p style="margin-bottom:0">
              <span style="display:block;margin-bottom:10px">Sie sind aktuell dem folgenden Schalter zugewiesen:</span>
              <span v-if="user && user.counters && user.counters.length > 0" style="display:inline-block;margin-right:10px;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">
                {{ user.counters[0].name }}
              </span>
            </p>
          </div>
          <div class="content-wrap">
            <h3>Warteschlangen</h3>
            <div v-for=" waiting_line in waiting_lines" v-bind:key=" waiting_line.id">
              <span style="display:inline-block;margin-right:10px;background: #F6F6F6;border: 1px solid #D0D0D0;border-radius: 4px;font-size:12px;padding:5px 8px">
                {{ waiting_line.name }}
              </span>
              <p style="margin: 20px 0 0 0">
                <router-link :to="'/admin/waiting_lines/'+waiting_line.id" class="button button-red" style="width:100%">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar" class="svg-inline--fa fa-calendar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="currentColor" d="M152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192H48V448z"></path>
                  </svg>
                  <span>Kalender öffnen</span>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'admin_user',
  data () {
    return {
      loading: true,
      user: {},
      appointments: [],
      all_appointments: [],
      waiting_lines: []
    }
  },
  methods: {
    async get_data() {
      this.loading = true;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user.id+'/appointments', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.appointments = response.data.appointments;
      });
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/waiting_lines', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.waiting_lines = response.data.waiting_lines;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments', {
        params: {
          dashboard: true
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.all_appointments = response.data.appointments;
      });
      this.loading = false;
    },
    async fetch_appointment() {
      var appointment = null
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments', {
        params: {
          dashboard: true
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        if (response.data.appointments.length > 0) {
          appointment = response.data.appointments[0]
        }
      })
      if (appointment) {
        this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+appointment.id, {
          counter_id: this.user.counters[0].id,
          user_id: this.user.id,
          status: 'called'
        }, {
          headers: { Authorization: this.$store.getters.get_token }
        })
        .then(response => {
          this.$notify({
            title: "Termin erfolgreich zugeordnet.",
            type: "success"
          });
          this.$router.push('/admin/appointments/'+response.data.appointment.id+'?user_id='+this.user.id);
        })
      } else {
        this.$notify({
          title: "Aktuell befindet sich niemand in der Warteschlange.",
          type: "error"
        });
        this.get_data();
      }
    },
    select_appointment(appointment) {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+appointment.id, {
        counter_id: this.user.counters[0].id,
        user_id: this.user.id,
        status: 'called'
      }, {
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.$notify({
          title: "Termin erfolgreich zugeordnet.",
          type: "success"
        });
        this.$router.push('/admin/appointments/'+response.data.appointment.id+'?user_id='+this.user.id);
      })
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
